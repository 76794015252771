import React, { useEffect, useRef, useState } from 'react';
import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import './App.css';

function App() {
  const canvasContainerRef = useRef(null);
  const [loading, setLoading] = useState(true);
  const [model, setModel] = useState(null);

  useEffect(() => {
    // Create the scene
    const scene = new THREE.Scene();

    // Create the camera
    const camera = new THREE.PerspectiveCamera(50, 1, 0.1, 1000);
    camera.position.z = 3;

    // Create the renderer
    const renderer = new THREE.WebGLRenderer({ antialias: true });
    renderer.setSize(600, 600);
    renderer.setClearColor(0xeeeeee);

    // Append renderer to container
    canvasContainerRef.current.appendChild(renderer.domElement);

    // Add lights to the scene
    const ambientLight = new THREE.AmbientLight(0x404040, 2);
    scene.add(ambientLight);
    
    const pointLight = new THREE.PointLight(0xffffff, 1);
    pointLight.position.set(2, 3, 4);
    scene.add(pointLight);
    
    const spotLight = new THREE.SpotLight(0xffffff, 1);
    spotLight.position.set(-5, 10, 5);
    scene.add(spotLight);

    const hemisphereLight = new THREE.HemisphereLight(0xffffbb, 0x080820, 1);
    scene.add(hemisphereLight);

    const directionalLights = [];
    for (let i = 0; i < 6; i++) {
      const directionalLight = new THREE.DirectionalLight(0xffffff, 1);
      directionalLight.position.set(Math.cos(i * Math.PI / 3) * 5, 5, Math.sin(i * Math.PI / 3) * 5);
      scene.add(directionalLight);
      directionalLights.push(directionalLight);
    }

    // Load the GLB model
    const loader = new GLTFLoader();
    loader.load(
      'model.glb',
      (gltf) => {
        const loadedModel = gltf.scene;
        scene.add(loadedModel);
        const box = new THREE.Box3().setFromObject(loadedModel);
        const size = box.getSize(new THREE.Vector3()).length();
        const scale = 1.7 / size;
        loadedModel.scale.set(scale, scale, scale);
        setLoading(false);
        setModel(loadedModel);
      },
      undefined,
      (error) => {
        console.error(error);
      }
    );

    // Animation loop
    const animate = () => {
      requestAnimationFrame(animate);
      renderer.render(scene, camera);
    };
    animate();

    // Cleanup on component unmount
    return () => {
      canvasContainerRef.current.removeChild(renderer.domElement);
    };
  }, []);

  // Mouse events for model rotation
  const [isDragging, setIsDragging] = useState(false);
  const previousMousePosition = useRef({ x: 0, y: 0 });
  const rotationSpeed = 0.005;

  const handleMouseDown = (event) => {
    if (event.button === 0) {
      setIsDragging(true);
      previousMousePosition.current = { x: event.clientX, y: event.clientY };
    }
  };

  const handleMouseMove = (event) => {
    if (isDragging && model) {
      const deltaMove = {
        x: event.clientX - previousMousePosition.current.x,
        y: event.clientY - previousMousePosition.current.y,
      };
      model.rotation.y += deltaMove.x * rotationSpeed;
      model.rotation.x += deltaMove.y * rotationSpeed;
      previousMousePosition.current = { x: event.clientX, y: event.clientY };
    }
  };

  const handleMouseUp = () => setIsDragging(false);

  return (
    <div className="container">
      <header className="header">
        <h1>3D Model of EV Charger</h1>
        <p>
          This is a demonstration of a 3D model of an EV charger integrated into a webpage using Three.js and React. Click and drag to rotate the model.
        </p>
      </header>
      <div className="row">
        <div className="col-md-6">
          <div
            id="canvas-container"
            ref={canvasContainerRef}
            onMouseDown={handleMouseDown}
            onMouseMove={handleMouseMove}
            onMouseUp={handleMouseUp}
            onContextMenu={(e) => e.preventDefault()}
            style={{ position: 'relative', height: '600px', width: '600px' }} // Ensure it has a height
          >
            {loading && <div id="loading">Loading model...</div>}
          </div>
        </div>
        <div className="col-md-6">
          <h1 className='head'>MOBEC PRODUCT FOR CHARGING</h1>
          <p className='para'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
          <p className='para'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
        </div>
      </div>
      <footer className="footer">
        <p>&copy; 2024 Your Company Name. All rights reserved.</p>
      </footer>
    </div>
  );
}

export default App;
